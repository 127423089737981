





















































































































































































































































































































































































































.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-cascader {
      height: 32px !important;
    }
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.bh /deep/ .el-textarea__inner {
  height: 200px;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
